import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import t from "../locale"

const Header = ({ lang, translation, translationPost }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = function (e) {
        var scrollY = window.pageYOffset || document.documentElement.scrollTop
        var header = document.querySelector("header")

        if (scrollY >= 1) {
          header.classList.add("shrink")
        }

        scrollY === 0 && header.classList.remove("shrink")

        this.lastScroll = scrollY
      }
    }
  })

  if (typeof window !== "undefined") {
    if (isMenuOpen === true) {
      document.body.classList.add("appleFix")
    } else {
      document.body.classList.remove("appleFix")
    }
  }

  return (
    <header className="header">
      <div className="header__wrapper">
        <Link to={t("/", lang)} className="header__brand">
          <img
            src={require("../assets/images/logo-complete.svg")}
            className="img-fluid"
            alt=""
          />
        </Link>
        <div className={`header__wrapper-inner ${isMenuOpen ? "is-open" : ""}`}>
          <div className="header__subnav">
            <Link to={t("/offices/", lang)} activeClassName="current">
              {t("Offices", lang)}
            </Link>
            <Link to={t("/retail/", lang)} activeClassName="current">
              {t("Retail", lang)}
            </Link>
            <Link to={t("/unity-eye/", lang)} activeClassName="current">
              {t("UNITY Eye", lang)}
            </Link>
          </div>
          <div className="header__nav">
            <Link to={t("/about/", lang)} activeClassName="current">
              {t("About", lang)}
            </Link>
            <Link to={t("/location/", lang)} activeClassName="current">
              {t("Location", lang)}
            </Link>
            <Link to={t("/krakow-sky-run/", lang)} activeClassName="current">
              {t("Krakow Sky Run", lang)}
            </Link>
            <Link to={t("/stories/", lang)} activeClassName="current">
              {t("Stories", lang)}
            </Link>
            <Link to={t("/contact/", lang)} activeClassName="current">
              {t("Contact", lang)}
            </Link>
            {translation && (
              <Link to={translation} activeClassName="current">
                {lang === "en" ? "PL" : "EN"}
              </Link>
            )}
            {translationPost &&
              translationPost.map(node => (
                <Link to={node.uri}>{node.language.code}</Link>
              ))}
          </div>
        </div>
      </div>

      <button
        className="header__mobile"
        onClick={e => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? t("Close", lang) : "Menu"}
      </button>
    </header>
  )
}

export default Header

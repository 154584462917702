import tranlations_en from "./en"
import tranlations_pl from "./pl"

const t = (name, lang = "en") => {
  lang = lang.toLowerCase()
  try {
    switch (lang) {
      case "en":
        return tranlations_en[name] || name
      case "pl":
        return tranlations_pl[name] || name
      default:
        return name
    }
  } catch (error) {
    return name
  }
}
export default t

import React from "react"
import { CookiesProvider, withCookies } from "react-cookie"

import Header from "./header"
import Footer from "./footer"

import "../assets/sass/style.scss"

class Layout extends React.Component {
  state = {
    cookieOpen: false,
  }

  componentDidMount() {
    const { cookies } = this.props
    const isAcceptedCoookie = !!cookies.get("cookie-accept-unity")
    !isAcceptedCoookie && this.setState({ cookieOpen: true })
  }

  acceptCookie = () => {
    const { cookies } = this.props

    const promiseSetCookie = new Promise(resolve =>
      resolve(cookies.set("cookie-accept-unity", "active", { path: "/" }))
    )
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false })
    })
  }

  render() {
    const {
      children,
      lang,
      translation,
      contactFooter,
      translationPost,
    } = this.props

    return (
      <>
        <CookiesProvider>
          <span id="top"></span>
          <Header
            lang={lang}
            translation={translation}
            translationPost={translationPost}
          />
          <main>{children}</main>
          <Footer lang={lang} contactFooter={contactFooter} />
          {
            // <div
            //   className={`cookie-baner ${this.state.cookieOpen ? "open" : ""}`}
            // >
            //   <p>
            //     Ta strona wykorzystuje pliki Cookies do poprawnego działania.{" "}
            //     <a href="/polityka-cookies/" target="_blank">
            //       Więcej informacji
            //     </a>
            //     .
            //   </p>
            //   <button
            //     className="btn-custom btn-custom--gold"
            //     onClick={this.acceptCookie}
            //   >
            //     Zaakceptuj
            //   </button>
            // </div>
          }
        </CookiesProvider>
      </>
    )
  }
}

export default withCookies(Layout)

import React from "react"
import { Link } from "gatsby"

import ContactForm from "./contactForm"

import t from "../locale"

const Footer = ({ lang, contactFooter }) => {
  return (
    <>
      <section
        className={`global-section-inquiries global__padd${
          contactFooter ? " global-section-inquiries--contact" : ""
        }`}
      >
        <div className="row align-items-center">
          <div className="col-md-5">
            <h3>{t("For leasing inquiries", lang)}</h3>
          </div>
          <div className="col-md-7">
            <span className="global__line"></span>
          </div>
        </div>

        <div className="global-section-inquiries__divider"></div>

        <div className="row">
          <div className="col-md-5">
            <img src={require("../assets/images/logo-jll.png")} alt="" />
          </div>
          <div className="col-md-7">
            <div className="global-section-inquiries__inner--right">
              <div className="row">
                <div className="col-sm">
                  <p>{t("General contact", lang)}</p>
                  <h4>GD&K Consulting</h4>
                  <address>
                    <a href="mailto:biuro@gdkc.pl">biuro@gdkc.pl</a>
                    <a href="tel:+48 608 323 778">+48 608 323 778</a>
                  </address>
                </div>
                <div className="col-sm">
                  <p>{t("Offices & retail opportunities", lang)}</p>
                  <h4>Agnieszka Majka – Pietruszka</h4>
                  <address>
                    <a href="mailto:a.majkapietruszka@jll.com">
                      a.majkapietruszka@jll.com
                    </a>
                    <a href="tel:+48 735 198 028">+48 735 198 028</a>
                  </address>
                  <h4>Joanna Tomala</h4>
                  <address>
                    <a href="mailto:joanna.tomala@jll.com">
                      joanna.tomala@jll.com
                    </a>
                    <a href="tel:+48 508 122 312">+48 508 122 312</a>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="global-section-contact global__padd">
        <Link
          to={t("/contact/", lang)}
          className={`global-section-contact__wrapper${
            contactFooter ? " global-section-contact__wrapper--contact" : ""
          }`}
        >
          <span>{t("Contact Form", lang)}</span>
          <strong>{t("Contact Us", lang)}</strong>
        </Link>
        {contactFooter && (
          <div className="global-section-contact__form">
            <ContactForm lang={lang} />
          </div>
        )}
      </section>

      <footer className="footer global__pad">
        <div className="row">
          <div className="col-md-2 col-6">
            <strong>{t("CUSTOMER SERVICE", lang)}</strong>
            <div className="footer__nav">
              <Link to={t("/offices/", lang)}>{t("Offices", lang)}</Link>
              <Link to={t("/retail/", lang)}>{t("Retail", lang)}</Link>
              <Link to={t("/unity-eye/", lang)}>{t("UNITY Eye", lang)}</Link>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <strong>{t("INFORMATION", lang)}</strong>
            <div className="footer__nav">
              <Link to={t("/", lang)}>{t("Home", lang)}</Link>
              <Link to={t("/about/", lang)}>{t("About", lang)}</Link>
              <Link to={t("/location/", lang)}>{t("Location", lang)}</Link>
              <Link to={t("/contact/", lang)}>{t("Contact", lang)}</Link>
            </div>
          </div>
          {contactFooter && (
            <div className="col-md-8 col-12">
              <strong>{t("Project Owner", lang)}</strong>
              <div className="footer__owner">
                <strong>{t("UNIQA – 85% Shareholder", lang)}</strong>
                <p>
                  {t(
                    "The UNIQA Group is one of the leading insurance groups for its core markets of Austria and Central and Eastern Europe (CEE). Around 21,300 employees and exclusive sales partners serve around 15.5 million customers in 18 countries. UNIQA is the second-largest insurance group in Austria with a market share of more than 21 per cent. UNIQA operates in 15 markets in the CEE growth region. UNIQAs RE Asset Manager, UNIQA Real Estate Management GmbH manages more than € 2.5 billion assets in real estate which totals in 256 real estate objects.",
                    lang
                  )}
                </p>
                <strong>{t("GD&K Group – 15% Shareholder", lang)}</strong>
                <p>
                  {t(
                    "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. It presently manages projects with the joint total value approximating € 250 million. Its flagship projects include the Carpathia Office House in Warsaw as well as Avia and Meduza office buildings in Krakow, Hotel Park Inn by Radisson in the centre of Krakow, but also residential projects Ruczaj Park and boutique tenements in Krakow’s city centre.",
                    lang
                  )}
                </p>
              </div>
              <div className="footer__owner">
                <strong>DISCLAIMER</strong>
                <p>
                  &copy;{" "}
                  {t(
                    "“Treimorfa Project Sp. z o.o.” with its registered office in Krakow (31-511), at ul. Mogilska 65/510, Tax Identification Number [NIP] 676-231-18-71, KRS 0000249132, National Official Business Register [REGON] no. 12017698000000, the District Court for Krakow-Śródmieście in Krakow,11th Commercial Division of the National Court Register, share capital PLN 40,000,000. The information presented on this web page (here and after referred to as the “Information”) do not constitute an offer, but solely an invitation for negotiations. The Information presented is of an exclusively indicative nature, i.e. it does not constitute representations nor warranties of specific characteristics of the object. In all matters regarding the Information (related thereto or to the subject thereof) applicable provisions of the law in force in the territory of the Republic of Poland shall apply, whereas potential disputes shall be settled by Polish courts competent for the accountable entity’s seat. The Information is neither addressed to consumers (in the broadest meaning of this legal term) nor to specific individuals. The [above-] mentioned commercialising agencies (as well as other real estate agents) are not authorised to submit declarations of intent or knowledge, representations or warranties, insofaras it does not stem directly from the power of attorney granted to them and disclosed to the other party",
                    lang
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="footer__copy">
          <div className="footer__social">
            <a
              href="https://www.instagram.com/unity.centre/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../assets/images/ico-instagram.svg")} alt="" />
            </a>
            <a
              href="https://www.facebook.com/Unity-Centre-1699274347008043/?fref=ts"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../assets/images/ico-facebook.svg")} alt="" />
            </a>
            <a
              href="https://www.linkedin.com/company/unity-centre/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={require("../assets/images/ico-linkedin.svg")} alt="" />
            </a>
          </div>
          <p>&copy; UnityCentre {new Date().getFullYear()}</p>
          <a
            className="footer__dev"
            href="https://adream.pl/"
            target="_blank"
            rel="noreferrer"
          >
            Proudly developed by
          </a>
        </div>
      </footer>
    </>
  )
}

export default Footer

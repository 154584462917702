const tranlations_en = {
  // Header
  "/": "/",
  Home: "Home",
  "/offices/": "/offices/",
  Offices: "Offices",
  "/retail/": "/retail/",
  Retail: "Retail",
  "/unity-eye/": "/unity-eye/",
  "UNITY Eye": "UNITY Eye",
  "/about/": "/about/",
  About: "About",
  "/location/": "/location/",
  Location: "Location",
  "/krakow-sky-run/": "/krakow-sky-run/",
  "Krakow Sky Run": "Krakow Sky Run",
  "/stories/": "/stories/",
  Stories: "Stories",
  "/covid-19/": "/covid-19/",
  "Covid-19": "Covid-19",
  "/contact/": "/contact/",
  Contact: "Contact",
  Close: "Close",

  // Footer
  "CUSTOMER SERVICE": "CUSTOMER SERVICE",
  "/download/": "/download/",
  Download: "Download",
  INFORMATION: "INFORMATION",
  "CONTACT US": "CONTACT US",
  "Project Owner": "Project Owner",
  "UNIQA – 85% Shareholder": "UNIQA – 85% Shareholder",
  "The UNIQA Group is one of the leading insurance groups for its core markets of Austria and Central and Eastern Europe (CEE). Around 21,300 employees and exclusive sales partners serve around 15.5 million customers in 18 countries. UNIQA is the second-largest insurance group in Austria with a market share of more than 21 per cent. UNIQA operates in 15 markets in the CEE growth region. UNIQAs RE Asset Manager, UNIQA Real Estate Management GmbH manages more than € 2.5 billion assets in real estate which totals in 256 real estate objects.":
    "The UNIQA Group is one of the leading insurance groups for its core markets of Austria and Central and Eastern Europe (CEE). Around 21,300 employees and exclusive sales partners serve around 15.5 million customers in 18 countries. UNIQA is the second-largest insurance group in Austria with a market share of more than 21 per cent. UNIQA operates in 15 markets in the CEE growth region. UNIQAs RE Asset Manager, UNIQA Real Estate Management GmbH manages more than € 2.5 billion assets in real estate which totals in 256 real estate objects.",
  "GD&K Group – 15% Shareholder": "GD&K Group – 15% Shareholder",
  "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. It presently manages projects with the joint total value approximating € 250 million. Its flagship projects include the Carpathia Office House in Warsaw as well as Avia and Meduza office buildings in Krakow, Hotel Park Inn by Radisson in the centre of Krakow, but also residential projects Ruczaj Park and boutique tenements in Krakow’s city centre.":
    "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. It presently manages projects with the joint total value approximating € 250 million. Its flagship projects include the Carpathia Office House in Warsaw as well as Avia and Meduza office buildings in Krakow, Hotel Park Inn by Radisson in the centre of Krakow, but also residential projects Ruczaj Park and boutique tenements in Krakow’s city centre.",
  "“Treimorfa Project Sp. z o.o.” with its registered office in Krakow (31-511), at ul. Mogilska 65/510, Tax Identification Number [NIP] 676-231-18-71, KRS 0000249132, National Official Business Register [REGON] no. 12017698000000, the District Court for Krakow-Śródmieście in Krakow,11th Commercial Division of the National Court Register, share capital PLN 40,000,000. The information presented on this web page (here and after referred to as the “Information”) do not constitute an offer, but solely an invitation for negotiations. The Information presented is of an exclusively indicative nature, i.e. it does not constitute representations nor warranties of specific characteristics of the object. In all matters regarding the Information (related thereto or to the subject thereof) applicable provisions of the law in force in the territory of the Republic of Poland shall apply, whereas potential disputes shall be settled by Polish courts competent for the accountable entity’s seat. The Information is neither addressed to consumers (in the broadest meaning of this legal term) nor to specific individuals. The [above-] mentioned commercialising agencies (as well as other real estate agents) are not authorised to submit declarations of intent or knowledge, representations or warranties, insofaras it does not stem directly from the power of attorney granted to them and disclosed to the other party":
    "“Treimorfa Project Sp. z o.o.” with its registered office in Krakow (31-511), at ul. Mogilska 65/510, Tax Identification Number [NIP] 676-231-18-71, KRS 0000249132, National Official Business Register [REGON] no. 12017698000000, the District Court for Krakow-Śródmieście in Krakow,11th Commercial Division of the National Court Register, share capital PLN 40,000,000. The information presented on this web page (here and after referred to as the “Information”) do not constitute an offer, but solely an invitation for negotiations. The Information presented is of an exclusively indicative nature, i.e. it does not constitute representations nor warranties of specific characteristics of the object. In all matters regarding the Information (related thereto or to the subject thereof) applicable provisions of the law in force in the territory of the Republic of Poland shall apply, whereas potential disputes shall be settled by Polish courts competent for the accountable entity’s seat. The Information is neither addressed to consumers (in the broadest meaning of this legal term) nor to specific individuals. The [above-] mentioned commercialising agencies (as well as other real estate agents) are not authorised to submit declarations of intent or knowledge, representations or warranties, insofaras it does not stem directly from the power of attorney granted to them and disclosed to the other party",

  // Contact
  "Contact Form": "Contact Form",
  "Contact Us": "Contact Us",
  Name: "Name",
  Company: "Company",
  "E-mail": "E-mail",
  Phone: "Phone",
  "How can we help you?": "How can we help you?",
  "How did you hear about us?": "How did you hear about us?",
  "Sending..": "Sending..",
  "Send message": "Send message",
  "Message sent!": "Message sent!",
  "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. Manages projects with the joint total value approximating €250 milion.":
    "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. Manages projects with the joint total value approximating €250 milion.",
  "For leasing inquiries": "For leasing inquiries",
  "Offices & retail opportunities": "Offices & retail opportunities",
  "For press inquiries": "For press inquiries",
  "General contact": "General contact",
  "Or ask your favourite real estate agent!":
    "Or ask your favourite real estate agent!",
  "CONSENT TO RECEIVE A DEDICATED TRADE OFFER - WE NEED IT IN ORDER TO CONTACT YOU. I consent to the processing of my personal data by TREIMORFA PROJECT SP. Z O.O. in order to receive the information about the offers of the Company's commercial partners, included in the Company's offer.":
    "CONSENT TO RECEIVE A DEDICATED TRADE OFFER - WE NEED IT IN ORDER TO CONTACT YOU. I consent to the processing of my personal data by TREIMORFA PROJECT SP. Z O.O. in order to receive the information about the offers of the Company's commercial partners, included in the Company's offer.",
  "CONSENT TO RECEIVE PHONE CALLS - IT IS ALWAYS FASTER TO DISCUSS THE SUBJECT DIRECTLY WITH EACH OTHER. I agree to receive information and offers from TREIMORFA PROJECT SP. Z O.O. via direct marketing, by using telecommunication devices of which I am a user of.":
    "CONSENT TO RECEIVE PHONE CALLS - IT IS ALWAYS FASTER TO DISCUSS THE SUBJECT DIRECTLY WITH EACH OTHER. I agree to receive information and offers from TREIMORFA PROJECT SP. Z O.O. via direct marketing, by using telecommunication devices of which I am a user of.",

  // GlobalTiles
  "Businesses are discovering the benefits of the base in Krakow's highest most desirable building.":
    "Businesses are discovering the benefits of the base in Krakow's highest most desirable building.",
  "Retail is at last rebalancing in favour of human experience. Learn more how to be part of it.":
    "Retail is at last rebalancing in favour of human experience. Learn more how to be part of it.",
  "Krakow’s highest and best view offers visitors breathtaking panoramas of the city.":
    "Krakow’s highest and best view offers visitors breathtaking panoramas of the city.",
  "Wkrótce otwarcie…": "Coming soon…",

  // GlobalOffices
  "Your office with human-centred design":
    "Your office with human-centred design",
  "Exceptional location": "Exceptional location",
  "The UNITY CENTRE complex is perfectly connected with all parts of Krakow and it is located in the heart of the city in the immediate neighborhood of the University Campus and Botanic Garden. Commute at easy! Here you will benefit from an extensive infrastructure.":
    "The UNITY CENTRE complex is perfectly connected with all parts of Krakow and it is located in the heart of the city in the immediate neighborhood of the University Campus and Botanic Garden. Commute at easy! Here you will benefit from an extensive infrastructure.",
  "Relationship building space": "Relationship building space",
  "UNITY CENTRE was designed to foster integration and maintenance of natural bonds. The spark of innovation is always born in contact with another person.":
    "UNITY CENTRE was designed to foster integration and maintenance of natural bonds. The spark of innovation is always born in contact with another person.",
  "Highest space making standards": "Highest space making standards",
  "Feel inspired by UNITY Square and plenty of space to choose from: flexible workspaces and meeting spots, indoor and outdoor co-working spaces, café terraces and tea gardens, canteens and restaurants. The choice is yours!":
    "Feel inspired by UNITY Square and plenty of space to choose from: flexible workspaces and meeting spots, indoor and outdoor co-working spaces, café terraces and tea gardens, canteens and restaurants. The choice is yours!",
  "UNITY CENTRE is equipped with modern technologies, which not only create a friendly ambience but will also reduce operational costs. Green solutions will ensure cost effectiveness while at the same time your organization will be taking care of the environment.":
    "UNITY CENTRE is equipped with modern technologies, which not only create a friendly ambience but will also reduce operational costs. Green solutions will ensure cost effectiveness while at the same time your organization will be taking care of the environment.",

  // OfficesSliders
  "Sample arrangements of an office": "Sample arrangements of an office",
  "4 tenants": "4 tenants",
  "(floor 3-7)": "(floor 3-7)",
  "(floor 10-24)": "(floor 10-24)",
  "Download Floor Plan": "Download Floor Plan",
  "Modern version": "Modern version",
  "Traditional version": "Traditional version",
  "sqm GLA": "sqm GLA",
  workplaces: "workplaces",
  Tenant: "Tenant",

  "3 office rooms / 1 person": "3 office rooms / 1 person",
  "Reception desk": "Reception desk",
  "6 conference rooms": "6 conference rooms",
  Kitchen: "Kitchen",
  "Relax and lounge area": "Relax and lounge area",
  "4 storage rooms": "4 storage rooms",
  "2 office rooms / 1 person": "2 office rooms / 1 person",
  "Relax area": "Relax area",
  "4 office rooms / 2 persons": "4 office rooms / 2 persons",
  "4 conference rooms": "4 conference rooms",
  "2 phonebooths": "2 phonebooths",
  "60 workplaces": "60 workplaces",
  "4 office rooms / 1 person": "4 office rooms / 1 person",
  "2 office rooms / 3 persons": "2 office rooms / 3 persons",
  "2 conference rooms": "2 conference rooms",
  Kitchenette: "Kitchenette",
  "2 storage rooms": "2 storage rooms",
  "13 conference rooms": "13 conference rooms",
  "7 focus rooms": "7 focus rooms",
  "Team work areas": "Team work areas",
  "Assembly hall": "Assembly hall",
  "Phone booths": "Phone booths",
  "Coffee points": "Coffee points",
  "1 office room / 1 person": "1 office room / 1 person",
  "9 conference rooms": "9 conference rooms",
  "1 storage room": "1 storage room",

  "3,2m - height to slab <br /> 2,7m - to suspended ceiling":
    "3,2m - height to slab <br /> 2,7m - to suspended ceiling",
  "Openable windows": "Openable windows",
  "Raised technical floor": "Raised technical floor",
  "HVAC system <br /> air conditioning": "HVAC system <br /> air conditioning",
  "Suspended ceiling (optional)": "Suspended ceiling (optional)",
  "Min. 7,5 sqm workspace / <br /> 1 person":
    "Min. 7,5 sqm workspace / <br /> 1 person",
  "Floorboxes / IT facilities": "Floorboxes / IT facilities",
  Sprinklers: "Sprinklers",
  floors: "floors",

  // Map
  "St.": "St.",
  "By car:": "By car:",
  "On foot:": "On foot:",
  ATM: "ATM",
  "Administrative Department of the City Hall":
    "Administrative Department of the City Hall",
  "Walking along Powstania Warszawskiego Avenue, within a few minutes, Administrative Department of the City Hall, located in one of the three famous 'Żyletkowce' - monuments of post-war modernism. This is where we will arrange matters related to checking in and collecting the driving license":
    "Walking along Powstania Warszawskiego Avenue, within a few minutes, Administrative Department of the City Hall, located in one of the three famous 'Żyletkowce' - monuments of post-war modernism. This is where we will arrange matters related to checking in and collecting the driving license",
  "The Botanical Garden of the Jagiellonian University":
    "The Botanical Garden of the Jagiellonian University",
  "The oldest botanical garden in Poland, for over two hundred years it has been a place of excursions, scientific research and artistic inspiration. On the area of ​​almost 10 hectares there are greenhouses, mountain climbing plants and a palm house with 7,000 species and varieties of plants.":
    "The oldest botanical garden in Poland, for over two hundred years it has been a place of excursions, scientific research and artistic inspiration. On the area of ​​almost 10 hectares there are greenhouses, mountain climbing plants and a palm house with 7,000 species and varieties of plants.",
  "University of Economics": "University of Economics",
  "The biggest and second oldest university in Poland with an economic profile educates 24,000 full-time and extramural students annually, and has a public swimming pool and a sports hall.":
    "The biggest and second oldest university in Poland with an economic profile educates 24,000 full-time and extramural students annually, and has a public swimming pool and a sports hall.",
  "District Court in Kraków": "District Court in Kraków",
  "Actually a complex of court buildings from the turn of the 1960s and 1970s, located between Przy Rondzie and Mosiężnicza street. Despite more and less successful changes and modernization, as well as the extension by a member of the Court of Appeal, it still remains an interesting object of modernist architecture.":
    "Actually a complex of court buildings from the turn of the 1960s and 1970s, located between Przy Rondzie and Mosiężnicza street. Despite more and less successful changes and modernization, as well as the extension by a member of the Court of Appeal, it still remains an interesting object of modernist architecture.",
  "Central Station": "Central Station",
  "The modernized, located entirely under the platforms, is located in the very center of Krakow - a 10-minute walk separates travelers from the Main Market Square or the UNITY CENTRE office complex. Connected directly to the terminal of the Balice airport, it provides a convenient connection with Kraków Airport in 18 minutes.":
    "The modernized, located entirely under the platforms, is located in the very center of Krakow - a 10-minute walk separates travelers from the Main Market Square or the UNITY CENTRE office complex. Connected directly to the terminal of the Balice airport, it provides a convenient connection with Kraków Airport in 18 minutes.",
  "Mogilskie Roundabout": "Mogilskie Roundabout",
  "Connected by a walkway and a bicycle path with the UNITY CENTRE, it is the largest transfer hub serving about half of the tram and bus lines in the central part of Krakow. Architecture and art enthusiasts will appreciate the exposed ruins of Fort Lubicz or the mosaic dedicated to the works of Stanisław Wyspiański. The roundabout is connected by an underground tunnel with the Central Railway Station and Galeria Krakowska mall.":
    "Connected by a walkway and a bicycle path with the UNITY CENTRE, it is the largest transfer hub serving about half of the tram and bus lines in the central part of Krakow. Architecture and art enthusiasts will appreciate the exposed ruins of Fort Lubicz or the mosaic dedicated to the works of Stanisław Wyspiański. The roundabout is connected by an underground tunnel with the Central Railway Station and Galeria Krakowska mall.",
  "Vistula Boulevards": "Vistula Boulevards",
  "True a paradise for walkers, runners and cyclists  - flood embankments located on both sides of the Vistula, which function as green recreational areas on a daily basis.":
    "True a paradise for walkers, runners and cyclists  - flood embankments located on both sides of the Vistula, which function as green recreational areas on a daily basis.",
  "Market Square": "Market Square",
  "The largest commercial square in medieval Europe, the heart of Krakow and the main tourist attraction visited by 10 million domestic and foreign tourists yearly.":
    "The largest commercial square in medieval Europe, the heart of Krakow and the main tourist attraction visited by 10 million domestic and foreign tourists yearly.",
  "Małopolska Voivodship Office": "Małopolska Voivodship Office",
  "District Prosecutor's Office": "District Prosecutor's Office",
  "Court of Appeal in Krakow": "Court of Appeal in Krakow",
  "Krakow Opera": "Krakow Opera",
  "Strzelecki Park": "Strzelecki Park",
  "Marian Eile Sqaure": "Marian Eile Sqaure",

  // Krakow Sky Run
  "Round 1": "Round 1",
  "Round 2": "Round 2",
  "The Best Time": "The Best Time",
  "of woman": "of woman",
  "of men": "of men",
}

export default tranlations_en

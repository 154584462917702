const tranlations_pl = {
  // Header
  "/": "/pl/",
  Home: "Strona główna",
  "/offices/": "/pl/biura/",
  Offices: "Biura",
  "/retail/": "/pl/lokale/",
  Retail: "Lokale",
  "/unity-eye/": "/pl/unity-eye/",
  "UNITY Eye": "UNITY Eye",
  "/about/": "/pl/o-nas/",
  About: "O Nas",
  "/location/": "/pl/lokalizacja/",
  Location: "Lokalizacja",
  "/krakow-sky-run/": "/pl/krakow-sky-run/",
  "Krakow Sky Run": "Kraków Sky Run",
  "/stories/": "/pl/blog/",
  Stories: "Blog",
  "/covid-19/": "/pl/covid-19/",
  "Covid-19": "Covid-19",
  "/contact/": "/pl/kontakt/",
  Contact: "Kontakt",
  Close: "Zamknij",

  // Footer
  "CUSTOMER SERVICE": "Obsługa klienta",
  "/download/": "/pobierz/",
  Download: "Pobierz",
  INFORMATION: "Informacje",
  "CONTACT US": "Skontaktuj się",
  "Project Owner": "Właściciel projektu",
  "UNIQA – 85% Shareholder": "UNIQA – właściciel 85% udziałów",
  "The UNIQA Group is one of the leading insurance groups for its core markets of Austria and Central and Eastern Europe (CEE). Around 21,300 employees and exclusive sales partners serve around 15.5 million customers in 18 countries. UNIQA is the second-largest insurance group in Austria with a market share of more than 21 per cent. UNIQA operates in 15 markets in the CEE growth region. UNIQAs RE Asset Manager, UNIQA Real Estate Management GmbH manages more than € 2.5 billion assets in real estate which totals in 256 real estate objects.":
    "Grupa UNIQA jest jedną z wiodących grup ubezpieczeniowych na swoich głównych rynkach w Austrii i Europie Środkowo-Wschodniej (CEE). Około 21 300 pracowników i wyłącznych partnerów handlowych obsługuje około 15,5 miliona klientów w 18 krajach. UNIQA jest drugą co do wielkości grupą ubezpieczeniową w Austrii z udziałem w rynku przekraczającym 21%. UNIQA działa na 15 rynkach w rozwijającym się regionie Europy Środkowo-Wschodniej. UNIQA RE Asset Manager, UNIQA Real Estate Management GmbH zarządza nieruchomościami o wartości ponad 2,5 miliarda euro, które obejmują łącznie 256 obiektów.",
  "GD&K Group – 15% Shareholder": "GD&K Group – właściciel 15% udziałów",
  "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. It presently manages projects with the joint total value approximating € 250 million. Its flagship projects include the Carpathia Office House in Warsaw as well as Avia and Meduza office buildings in Krakow, Hotel Park Inn by Radisson in the centre of Krakow, but also residential projects Ruczaj Park and boutique tenements in Krakow’s city centre.":
    "GD&K Group jest prywatną spółką zajmującą się inwestycjami, developmentem i zarządzaniem w obszarze nieruchomości założoną w 1996 roku obecną w Polsce — w Krakowie i Warszawie. W jej zarządzaniu są aktualnie projekty o łącznej wartości blisko 250 milionów euro. Do flagowych projektów można zaliczyć: biurowce Carpathia w Warszawie, Avia i Meduza w Krakowie, hotel Park Inn by Radisson w centrum Krakowa jak i projekty mieszkaniowe Ruczaj Park oraz butikowe kamienice w centrum Krakowa.",
  "“Treimorfa Project Sp. z o.o.” with its registered office in Krakow (31-511), at ul. Mogilska 65/510, Tax Identification Number [NIP] 676-231-18-71, KRS 0000249132, National Official Business Register [REGON] no. 12017698000000, the District Court for Krakow-Śródmieście in Krakow,11th Commercial Division of the National Court Register, share capital PLN 40,000,000. The information presented on this web page (here and after referred to as the “Information”) do not constitute an offer, but solely an invitation for negotiations. The Information presented is of an exclusively indicative nature, i.e. it does not constitute representations nor warranties of specific characteristics of the object. In all matters regarding the Information (related thereto or to the subject thereof) applicable provisions of the law in force in the territory of the Republic of Poland shall apply, whereas potential disputes shall be settled by Polish courts competent for the accountable entity’s seat. The Information is neither addressed to consumers (in the broadest meaning of this legal term) nor to specific individuals. The [above-] mentioned commercialising agencies (as well as other real estate agents) are not authorised to submit declarations of intent or knowledge, representations or warranties, insofaras it does not stem directly from the power of attorney granted to them and disclosed to the other party":
    "„Treimorfa Project Sp. z o.o.” z siedzibą w Krakowie (31-511) przy ul. Mogilskiej 65/510, NIP 676-231-18-71, KRS 0000249132, REGON 12017698000000, Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie, XI WYDZIAŁ GOSPODARCZY KRS, kapitał zakładowy 40.000.000 zł. Informacje przedstawione na niniejszej stronie internetowej (dalej zwane „Informacjami”) nie stanowią oferty, lecz jedynie zaproszenie do rokowań. Przedstawione Informacje mają wyłącznie charakter orientacyjny, to jest nie stanowią żadnych oświadczeń woli i wiedzy lub zapewnień, ani przyrzeczenia publicznego lub gwarancji określonych właściwości rzeczy. We wszystkich sprawach dotyczących Informacji (związanych z nimi lub ich przedmiotem) stosuje się przepisy prawa obowiązującego na terytorium Rzeczypospolitej Polskiej, a ewentualne spory rozstrzygają sądy polskie według właściwości miejscowej dla podmiotu odpowiedzialnego. Informacje nie są skierowane do konsumentów (w najszerszym rozumieniu tego terminu prawnego) ani do konkretnych osób. Wymienione [wyżej] agencje komercjalizujące (podobnie jak inni pośrednicy nieruchomości) nie są umocowani do składania oświadczeń woli i wiedzy, zapewnień lub gwarancji, o ile nie wynika to wprost z udzielonego im i ujawnionego drugiej stronie pełnomocnictwa.",

  // Contact
  "Contact Form": "Formularz kontaktowy",
  "Contact Us": "Skontaktuj się",
  Name: "Imię",
  Company: "Firma",
  "E-mail": "E-mail",
  Phone: "Telefon kontaktowy",
  "How can we help you?": "Jak możemy Ci pomóc?",
  "How did you hear about us?": "Jak dowiedziałeś się o nas?",
  "Sending..": "Wysyłanie..",
  "Send message": "Wyślij",
  "Message sent!": "Wiadomość wysłana!",
  "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. Manages projects with the joint total value approximating €250 milion.":
    "GD&K Group is a private equity company established in 1996, with offices in Krakow and Warsaw, conducting operations in the area of real estate investment, development and management. Manages projects with the joint total value approximating €250 milion.",
  "For leasing inquiries": "Wynajem przestrzeni",
  "Offices & retail opportunities": "Biura i przestrzenie usługowe ",
  "For press inquiries": "Kontakt dla mediów",
  "General contact": "Kontakt w sprawach ogólnych",
  "Or ask your favourite real estate agent!":
    "Lub zapytaj się swojego ulubionego agenta nieruchomości!",
  "CONSENT TO RECEIVE A DEDICATED TRADE OFFER - WE NEED IT IN ORDER TO CONTACT YOU. I consent to the processing of my personal data by TREIMORFA PROJECT SP. Z O.O. in order to receive the information about the offers of the Company's commercial partners, included in the Company's offer.":
    "ZGODA NA DEDYKOWANĄ OFERTĘ HANDLOWĄ – POTRZEBUJEMY JEJ, ŻEBY ZACZĄĆ ROZMAWIAĆ. Wyrażam zgodę na przetwarzanie moich danych osobowych przez TREIMORFA PROJECT SP. Z O.O. w celu przesyłania informacji o ofertach partnerów handlowych Spółki, znajdujących się w ofercie Spółki.",
  "CONSENT TO RECEIVE PHONE CALLS - IT IS ALWAYS FASTER TO DISCUSS THE SUBJECT DIRECTLY WITH EACH OTHER. I agree to receive information and offers from TREIMORFA PROJECT SP. Z O.O. via direct marketing, by using telecommunication devices of which I am a user of.":
    "ZGODA NA KOMUNIKACJĘ TELEFONICZNĄ – ZAWSZE SZYBCIEJ JEST OMÓWIĆ TEMAT BEZPOŚREDNIO Zgadzam się na przesyłanie przez TREIMORFA PROJECT SP. Z O.O. informacji i ofert w ramach marketingu bezpośredniego za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem.",

  // GlobalTiles
  "Businesses are discovering the benefits of the base in Krakow's highest most desirable building.":
    "Firmy odkrywają zalety posiadania biura w najwyższym i najbardziej prestiżowym budynku w samym sercu Krakowa. ",
  "Retail is at last rebalancing in favour of human experience. Learn more how to be part of it.":
    "Tętniąca życiem przestrzeń to też otwarcie się na małe biznesy i usługi, które są niezbędne do codziennego funkcjonowania społeczności.",
  "Krakow’s highest and best view offers visitors breathtaking panoramas of the city.":
    "Najwyższy w Krakowie taras widokowy oferuje odwiedzającym zapierającą dech w piersiach panoramę miasta. ",
  "Wkrótce otwarcie…": "Wkrótce otwarcie…",

  // GlobalOffices
  "Your office with human-centred design":
    "Projektowanie zorientowane na człowieka",
  "Exceptional location": "Wyjątkowa lokalizacja",
  "The UNITY CENTRE complex is perfectly connected with all parts of Krakow and it is located in the heart of the city in the immediate neighborhood of the University Campus and Botanic Garden. Commute at easy! Here you will benefit from an extensive infrastructure.":
    "Wyjątkowa lokalizacja kompleksu UNITY CENTRE jest doskonale skomunikowana ze wszystkimi częściami Krakowa i znajduje się w samym centrum miasta, w bezpośrednim sąsiedztwie kampusu uniwersyteckiego i Ogrodu Botanicznego. ",
  "Relationship building space": "Przestrzeń budowania relacji",
  "UNITY CENTRE was designed to foster integration and maintenance of natural bonds. The spark of innovation is always born in contact with another person.":
    "UNITY CENTRE zostało zaprojektowane tak, aby wspierać integrację i utrzymywanie naturalnych więzi międzyludzkich. Wierzymy, że iskra innowacji rodzi się właśnie w momencie kontaktów i wymiany wiedzy oraz doświadczenia z drugim człowiekiem.",
  "Feel inspired by UNITY Square and plenty of space to choose from: flexible workspaces and meeting spots, indoor and outdoor co-working spaces, café terraces and tea gardens, canteens and restaurants. The choice is yours!":
    "Zainspiruj się UNITY Square i dużą różnorodnością przestrzeni do wyboru: elastyczne miejsca do pracy i spotkań, przestrzenie coworkingowe wewnętrzne i zewnętrzne, ogródki kawiarniane, kantyny i restauracje. Wybór należy do Ciebie!",
  "Highest space making standards": "Najwyższy standard",
  "UNITY CENTRE is equipped with modern technologies, which not only create a friendly ambience but will also reduce operational costs. Green solutions will ensure cost effectiveness while at the same time your organization will be taking care of the environment.":
    "Wysoka jakość oznacza zaangażowanie nowoczesnych technologii, które nie tylko odpowiadają za tworzenie przyjaznej atmosfery pracy, ale także obniżają koszty operacyjne najemców. Ekologiczne rozwiązania zapewnią efektywność kosztową i dbałość o przyszłość planety.",

  // OfficesSliders
  "Sample arrangements of an office": "Przykładowa aranżacja",
  "4 tenants": "4 najemców",
  "4 tenants (floor 2-8)": "4 najemców (piętro 2-8)",
  "(floor 3-7)": "(piętro 3-7)",
  "(floor 10-24)": "(piętro 10-24)",
  "Download Floor Plan": "Pobierz plan pięter",
  "Modern version": "Wersja nowoczesna",
  "Traditional version": "Wersja tradycyjna",
  "Modern version (floor 1)": "Wersja nowoczesna (piętro 1)",
  "Traditional version (floor 1)": "Wersja tradycyjna (piętro 1)",
  "Modern version (floor 2)": "Wersja nowoczesna (piętro 2)",
  "Traditional version (floor 2)": "Wersja tradycyjna (piętro 2)",
  "Modern version (floor 5)": "Wersja nowoczesna (piętro 5)",
  "Traditional version (floor 5)": "Wersja tradycyjna (piętro 5)",
  "Modern version (floor 2-4)": "Wersja nowoczesna (piętro 2-4)",
  "Traditional version (floor 2-4)": "Wersja tradycyjna (piętro 2-4)",
  "Modern version (floor 2-8)": "Wersja nowoczesna (piętro 2-8)",
  "Traditional version (floor 2-8)": "Wersja tradycyjna (piętro 2-8)",
  "sqm GLA": "m<sup>2</sup> GLA",
  workplaces: "stanowisk pracy",
  Tenant: "Najemca",

  "3 office rooms / 1 person": "3 gabinety 1 osobowe",
  "Reception desk": "Recepcja",
  "6 conference rooms": "6 sal konferencyjnych",
  Kitchen: "Kuchnia",
  "Relax and lounge area": "Strefa relaksu",
  "4 storage rooms": "4 magazyny",
  "1 storage room": "1 magazyn",
  "1 storage rooms": "1 magazyn",
  "2 office rooms / 1 person": "2 gabinety 1 osobowe",
  "Relax area": "Strefa relaksu",
  "4 office rooms / 2 persons": "4 gabinety 2 osobowe",
  "4 conference rooms": "4 sale konferencyjne",
  "2 phonebooths": "2 budki telefoniczne",
  "60 workplaces": "60 stanowisk pracy",
  "4 office rooms / 1 person": "4 gabinety 1 osobowe",
  "5 office rooms / 2 persons": "5 gabinetów 2 osobowe",
  "2 office rooms / 3 persons": "2 gabinety 3 osobowe",
  "3 office rooms / 2 person": "3 gabinety 2 osobowe",
  "2 office room / 2 person": "2 gabinety 2 osobowe",
  "1 office room / 4 persons": "1 gabinet 2 osobowy",
  "2 conference rooms": "2 sale konferencyjne",
  "3 conference rooms": "3 sale konferencyjne",
  Kitchenette: "Aneks kuchenny",
  "2 storage rooms": "2 magazyny",
  "13 conference rooms": "13 sal konferencyjnych",
  "7 focus rooms": "7 pomieszczeń strefy cichej",
  "Team work areas": "Strefy spotkań nieformalnych",
  "Assembly hall": "Audytorium",
  "Phone booths": "Budki telefoniczne",
  "Coffee points": "Coffee pointy",
  "1 office room / 1 person": "1 gabinet 1 osobowy",
  "9 conference rooms": "9 sal konferencyjnych",

  "3,2m - height to slab <br /> 2,7m - to suspended ceiling":
    "Minimalna wysokość 2,7m2",
  "Openable windows": "Otwierane okna",
  "Raised technical floor": "Podniesiona podłoga",
  "HVAC system <br /> air conditioning": "Klimatyzacja",
  "Suspended ceiling (optional)": "Sufity podwieszane (opcjonalne)",
  "Min. 7,5 sqm workspace / <br /> 1 person":
    "Min. 7,5m2 stanowisko <br/> pracy / 1 osoba",
  "Floorboxes / IT facilities": "Floorboxy",
  Sprinklers: "Tryskacze",
  floors: "piętra",

  // Map
  "St.": "ul.",
  "By car:": "Czas dojazdu:",
  "On foot:": "Pieszo:",
  ATM: "Bankomat",
  "Administrative Department of the City Hall": "Urząd Miasta Krakowa",
  "Walking along Powstania Warszawskiego Avenue, within a few minutes, Administrative Department of the City Hall, located in one of the three famous 'Żyletkowce' - monuments of post-war modernism. This is where we will arrange matters related to checking in and collecting the driving license":
    "Spacerując wzdłuż alei Powstania Warszawskiego dochodzimy po kilku minutach do Wydziału Spraw Administracyjnych Urzędu Miasta znajdującego się w jednym z trzech krakowskich 'Żyletkowców' – zabytków powojennego modernizmu. To tutaj załatwimy m.in. sprawy związane z zameldowaniem i odbierzemy prawo jazdy",
  "The Botanical Garden of the Jagiellonian University":
    "Ogród Botaniczny Uniwersytetu Jagiellońskiego",
  "The oldest botanical garden in Poland, for over two hundred years it has been a place of excursions, scientific research and artistic inspiration. On the area of ​​almost 10 hectares there are greenhouses, mountain climbing plants and a palm house with 7,000 species and varieties of plants.":
    "Najstarszy w Polsce ogród botaniczny od ponad dwustu lat jest miejscem wycieczek, badań naukowych i inspiracji artystycznych. Na powierzchni niemal 10 ha znajdują się szklarnie, alpinaria oraz palmiarnia z 7 tysiącami gatunków i odmian roślin.",
  "University of Economics": "Uniwersytet Ekonomiczny",
  "The biggest and second oldest university in Poland with an economic profile educates 24,000 full-time and extramural students annually, and has a public swimming pool and a sports hall.":
    "Druga najstarsza w Polsce uczelnia wyższa o profilu ekonomicznym kształcąca rocznie 24 000 studentów kierunków dziennych i zaocznych, na jej terenie znajdują się ogólnodostępne basen i hala sportowa.",
  "District Court in Kraków": "Sąd Okręgowy i Rejonowy w Krakowie",
  "Actually a complex of court buildings from the turn of the 1960s and 1970s, located between Przy Rondzie and Mosiężnicza street. Despite more and less successful changes and modernization, as well as the extension by a member of the Court of Appeal, it still remains an interesting object of modernist architecture.":
    "Właściwie zespół budynków sądowych z przełomu lat 60. I 70. XX w. usytuowany pomiędzy ul. Przy Rondzie i Mosiężniczą. Mimo bardziej i mniej udanych zmian i modernizacji oraz rozbudowy o człon  Sądu Apelacyjnego dalej pozostaje ciekawym obiektem architektury modernistycznej.",
  "Central Station": "Dworzec głowny",
  "The modernized, located entirely under the platforms, is located in the very center of Krakow - a 10-minute walk separates travelers from the Main Market Square or the UNITY CENTRE office complex. Connected directly to the terminal of the Balice airport, it provides a convenient connection with Kraków Airport in 18 minutes.":
    "The modernized, located entirely under the platforms, is located in the very center of Krakow - a 10-minute walk separates travelers from the Main Market Square or the UNITY CENTRE office complex. Connected directly to the terminal of the Balice airport, it provides a convenient connection with Kraków Airport in 18 minutes.",
  "Mogilskie Roundabout": "Rondo Mogilskie",
  "Connected by a walkway and a bicycle path with the UNITY CENTRE, it is the largest transfer hub serving about half of the tram and bus lines in the central part of Krakow. Architecture and art enthusiasts will appreciate the exposed ruins of Fort Lubicz or the mosaic dedicated to the works of Stanisław Wyspiański. The roundabout is connected by an underground tunnel with the Central Railway Station and Galeria Krakowska mall.":
    "Połączone chodnikiem i ścieżką rowerową z UNITY CENTRE jest największym hubem przesiadkowym obsługującym ok połowy linii tramwajowych i autobusowych w centralnej części Krakowa. Miłośnicy architektury i sztuki docenią wyeksponowane ruiny Fortu Lubicz czy mozaikę dedykowaną twórczości Stanisława Wyspiańskiego. Rondo jest połączone podziemnym tunelem z Dworcem PKP oraz Galerią Krakowską.",
  "Vistula Boulevards": "Bulwary Wiślane",
  "True a paradise for walkers, runners and cyclists  - flood embankments located on both sides of the Vistula, which function as green recreational areas on a daily basis.":
    "Zlokalizowane po obu stronach Wisły wały przeciwpowodziowe funkcjonujące na co dzień jako zielone tereny rekreacyjne - raj dla spacerowiczów, biegaczy i rowerzystów.",
  "Market Square": "Rynek Główny",
  "The largest commercial square in medieval Europe, the heart of Krakow and the main tourist attraction visited by 10 million domestic and foreign tourists yearly.":
    "Największy plac handlowy średniowiecznej Europy, serce Krakowa i główna atrrakcja turystyczna odwiedzana przez 10 milionów turystów krajowych i zagranicznych.",
  "Małopolska Voivodship Office": "Małopolski Urząd Wojewódzki",
  "District Prosecutor's Office": "Prokuratura Rejonowa i Okręgowa",
  "Court of Appeal in Krakow": "Sąd Apelacyjny w Krakowie",
  "Krakow Opera": "Opera Krakowska",
  "Strzelecki Park": "Park Strzelecki",
  "Marian Eile Sqaure": "Skwer im. Mariana Eilego",

  // Krakow Sky Run
  "Round 1": "Tura 1",
  "Round 2": "Tura 2",
  "The Best Time": "Najlepszy wynik",
  "of woman": "kobieta",
  "of men": "mężczyzna",
}

export default tranlations_pl
